import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Icon, LinkBox, Box } from "@quarkly/widgets";
import { BsDot } from "react-icons/bs";
import { TiArrowRight } from "react-icons/ti";
const defaultProps = {
	"width": "100%",
	"display": "flex",
	"align-items": "center",
	"justify-content": "center",
	"position": "static",
	"padding": "13px 0px 13px 0px",
	"top": "0px",
	"quarkly-title": "Banner-2",
	"background": "linear-gradient(90deg, rgb(24 189 179), rgb(181 240 56), rgb(255, 255, 255));",
	"md-flex-direction": "column",
	"md-display": "flex",
	"md-grid-gap": "8px"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 600 16px/1.5 --fontFamily-googleSourceSansPro",
			"color": "--dark",
			"md-text-align": "center",
			"children": "Приєднуйтесь до нас"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "bs",
			"icon": BsDot,
			"size": "16px",
			"md-display": "none"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "--dark",
			"md-text-align": "center",
			"children": "Скористайтеся можливістю вдосконалити свою гру в гольф в атмосфері неперевершеної краси та гостинності. "
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"color": "--light",
			"margin": "0px 0px 0px 16px",
			"text-decoration-line": "initial",
			"text-transform": "uppercase",
			"background": "--color-dark",
			"font": "normal 600 16px/1.5 --fontFamily-googleSourceSansPro",
			"padding": "6px 16px 7px 16px",
			"border-radius": "24px",
			"letter-spacing": "0.3px",
			"flex-direction": "row",
			"href": "/contacts"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 1px 0px",
			"text-transform": "initial",
			"children": "Написати"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "ti",
			"icon": TiArrowRight,
			"size": "24px",
			"margin": "0px 0px 0px 0px"
		}
	}
};

const Banner = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Box {...rest}>
		<Text {...override("text")} />
		<Icon {...override("icon")} />
		<Text {...override("text1")} />
		<LinkBox {...override("linkBox")}>
			<Text {...override("text2")} />
			<Icon {...override("icon1")} />
		</LinkBox>
		{children}
	</Box>;
};

Object.assign(Banner, { ...Box,
	defaultProps,
	overrides
});
export default Banner;